@import "./variables";


// body.swal2-height-auto {
//   height: 100% !important;
// }


.header_text {
  font-family: 'Orbitron', sans-serif;
}

.quote_text {
  font-family: 'Merienda One', cursive;
}

body {
  background-color: #ededed;
  height: 100%;
  min-width: 360px;
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.react-select__single-value {
  line-height: 21px !important;
}

#root {
  height: 100%;
  position: relative;
}

* {
  // overflow-wrap: break-word;

  &:focus {
    outline: none;
  }

  margin: 0;
  padding: 0;
  outline: none;
  // box-sizing: border-box;
}

.container {
  margin: auto !important;
  max-width: $mq-md !important;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  @media all and (min-width: $mq-sm) {
    padding-right: 24px;
    padding-left: 24px;
  }
}

.container-lg {
  margin: auto !important;
  max-width: $mq-lg !important;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  @media all and (min-width: $mq-sm) {
    padding-right: 24px;
    padding-left: 24px;
  }
}

.container-xl {
  margin: auto !important;
  max-width: $mq-xl !important;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  @media all and (min-width: $mq-sm) {
    padding-right: 24px;
    padding-left: 24px;
  }
}

html {
  height: 100%;
  font-size: 98%;
  line-height: 1.4;
}

@media (min-width: $mq-sm) {
  html {
    font-size: 100%;
  }
}

@media (min-width: $mq-md) {
  html {
    font-size: 105%;
  }
}

.button {
  padding: 0.5rem 0.7rem 0.5rem 0.7rem;
  border-radius: 3px;
  transition: all 0.2s;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
}

.button-text {
  font-size: 0.9rem;
  text-align: center;
}

.button-link {
  @extend .button;
  @extend .button-text;
  color: $color-primary;
  text-decoration: underline;
}





.button-primary {
  @extend .button;
  @extend .button-text;
  background-color: $color-primary;

  &:hover {
    background-color: rgba($color-primary, 0.7);
  }

  color: white;
}

.button-outline {
  @extend .button;
  @extend .button-text;
  background-color: white;

  &:hover {
    background-color: rgba($color-black, 0.1);
  }

  color: $color-black;
  border: 1px solid $color-black;
}

.aspect-ratio-box {
  height: 0;
  // overflow: hidden;
  padding-top: 591.44px / 1127.34px * 100%;
  background: white;
  position: relative;
}

.aspect-ratio-box-inside {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}