$mq-sm: 640px;
$mq-md: 768px;
$mq-lg: 1024px;
$mq-xl: 1280px;
$color-primary:rgb(52, 110, 122);
$color-secondary: rgb(90, 196, 135);
$color-secondary-2: rgb(49, 105, 117);
$color-secondary-3: rgb(43, 94, 104);
$color-secondary-4: rgb(34, 75, 83);
$color-black:#303232;
$color-utility: rgb(15, 24, 62);